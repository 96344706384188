<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="additionalCertificate"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            Buy Additional Certificate On Open Cover Policy -
            {{ singleBusiness.policyno }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="handleSubmit()">
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-4">
                <label for="">Policy Start Date</label
                ><input
                  class="form-control"
                  id="nstartdate"
                  type="date"
                  v-model="data.startDate"
                  @input="addDays(data.startDate, data.duration)"
                  required
                />
              </div>

              <div class="form-group col-md-4">
                <label for="">Policy Duration (In Days)</label
                ><input
                  class="form-control"
                  id="duration"
                  type="number"
                  min="1"
                  max="365"
                  v-model="data.duration"
                  placeholder="Enter Duration"
                  @input="addDays(data.startDate, data.duration)"
                  required
                />
              </div>

              <div class="form-group col-md-4">
                <label for="">Policy End Date</label
                ><input
                  class="form-control"
                  id="duration"
                  type="text"
                  v-model="metaData.policyEnd"
                  placeholder="Policy End Date"
                  disabled
                />
              </div>

              <!-- <div class="form-group col-md-2">
        <label for="">Endorsement</label
        ><input
          class="form-control"
          id="endorsement"
          type="text"
          v-model="data.endorsement"
          placeholder="Enter Endorsement"
          required
        />
      </div>

      <div class="form-group col-md-2">
        <label for="">Endorsement Option</label
        ><select
          v-model="data.endorsementOptions"
          class="form-control"
          id="endorsementoption"
          required
        >
          <option value="">Select Endorsement Option</option>
          <option
            v-for="option in endorsementOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div> -->

              <div class="form-group col-md-3">
                <label for="">Voyage Type</label
                ><select
                  @change="(this.data.premiumRate = ''), getRate()"
                  v-model="data.voyageType"
                  class="form-control"
                  id="voyagetype"
                  required
                >
                  <option value="">Select Voyage Type</option>
                  <option
                    v-for="voyage in voyageTypes"
                    :key="voyage.id"
                    :value="voyage.name"
                  >
                    {{ voyage.name }}
                  </option>
                </select>
              </div>

              <div class="form-group col-md-3">
                <label for="">Parking Type</label
                ><select
                  v-model="data.parkingType"
                  class="form-control"
                  id="parkingtype"
                  required
                >
                  <option value="">Select Parking Type</option>
                  <option
                    v-for="parkingType in parkingTypes"
                    :key="parkingType.id"
                    :value="parkingType.name"
                  >
                    {{ parkingType.name }}
                  </option>
                </select>
              </div>

              <div class="form-group col-md-3">
                <label for="">Conveyance</label
                ><select
                  v-model="data.conveyance"
                  class="form-control"
                  id="conveyance"
                  required
                >
                  <option value="">Select Conveyance</option>
                  <option
                    v-for="conveyance in conveyances"
                    :key="conveyance.id"
                    :value="conveyance.name"
                  >
                    {{ conveyance.name }}
                  </option>
                </select>
              </div>

              <div class="form-group col-md-3">
                <label for="">Cover Scope</label
                ><input
                  :value="singleBusiness.coverScope"
                  class="form-control"
                  id="coverscope"
                  disabled
                />
              </div>

              <div class="form-group col-md-4">
                <label for="">Nature Of Cargo</label
                ><select
                  v-model="data.natureofCargo"
                  class="form-control"
                  id="natureofcargo"
                  required
                >
                  <option value="">Select Nature Of Cargo</option>
                  <option
                    v-for="cargo in natureOfCargos"
                    :key="cargo.id"
                    :value="cargo.name"
                  >
                    {{ cargo.name }}
                  </option>
                </select>
              </div>

              <div class="form-group col-md-4">
                <label for="">Proformal Invoice Number</label
                ><input
                  class="form-control"
                  id="proformalinvoice"
                  type="text"
                  v-model="data.proformaInvoiceNo"
                  placeholder="Enter Proformal Invoice Number"
                  required
                />
              </div>

              <div class="form-group col-md-4">
                <label for="">Vessel</label
                ><input
                  class="form-control"
                  id="vessel"
                  type="text"
                  v-model="data.vessel"
                  placeholder="Enter Vessel"
                  required
                />
              </div>

              <div class="form-group col-md-2">
                <label for="">Type Of Cover</label
                ><select
                  v-model="data.typeofCover"
                  @change="getRate()"
                  class="form-control"
                  id="typeofCover"
                  required
                >
                  <option value="">Select Type Of Cover</option>
                  <option
                    v-for="cover in singleBusiness.addClauseOpenCover"
                    :key="cover"
                    :value="cover"
                  >
                    {{ cover }}
                  </option>
                </select>
              </div>

              <div class="form-group col-md-5">
                <label for="">Shipment Going From</label
                ><input
                  class="form-control"
                  id="fromaddress"
                  type="text"
                  v-model="data.from"
                  placeholder="Enter From Address"
                  required
                />
              </div>

              <div class="form-group col-md-5">
                <label for="">Shipment Going To</label
                ><input
                  class="form-control"
                  id="toaddress"
                  type="text"
                  v-model="data.to"
                  placeholder="Enter To Address"
                  required
                />
              </div>

              <div
                v-if="singleBusiness.addClause.length !== 0"
                class="form-group my-3 col-md-12"
              >
                <label for="">Additional Cover Clauses</label>
                <div class="row mx-1">
                  <div
                    v-for="clause in singleBusiness.addClause"
                    :key="clause"
                    class="form-check my-2 col-4"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      checked
                      disabled
                    />
                    <label class="form-check-label">
                      {{ clause }}
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group mb-5 col-md-12">
                <label for="">Cargo Description (Detailed)</label
                ><textarea
                  class="form-control"
                  v-model="data.cargoDescription"
                  id="cargodescription"
                  rows="3"
                  required
                ></textarea>
              </div>

              <div class="form-group col-md-2">
                <label for="">Currency</label
                ><select
                  @change="setCurrency(metaData.currency), calcPremium()"
                  v-model="metaData.currency"
                  class="form-control"
                  id="currency"
                  required
                >
                  <option :value="{}" v-if="data.exCurrency != ''">
                    {{ data.exCurrency }}
                  </option>
                  <option v-else :value="{}">Select Currency</option>
                  <option
                    v-for="currency in currencyRates"
                    :key="currency.id"
                    :value="currency"
                  >
                    {{ currency.exCurrency }}
                  </option>
                </select>
              </div>

              <div class="form-group col-md-2">
                <label for="">Exchange Rate</label
                ><input
                  class="form-control"
                  @input="calcPremium()"
                  id="exrate"
                  type="number"
                  step="0.001"
                  v-model="data.exRate"
                  placeholder=""
                  required
                />
              </div>

              <div v-if="user.role === 'Broker'" class="form-group col-md-4">
                <label for="">Credit Note No</label
                ><input
                  class="form-control"
                  id="creditnote"
                  type="text"
                  v-model="data.brokersRefNo"
                  placeholder="Enter Credit Not No."
                  required
                />
              </div>

              <div v-if="user.role === 'Broker'" class="form-group col-md-4">
                <label for="">Upload Credit Note Document</label
                ><input
                  class="form-control"
                  id="creditnoteupload"
                  type="file"
                  @change.prevent="handleFileUpload($event)"
                  required
                />
              </div>

              <div class="form-group col-md-4">
                <label for="">Invoiced Value</label
                ><input
                  class="form-control"
                  id="invoicedValue"
                  @input="calcPremium()"
                  type="text"
                  v-model="data.invoicedValue"
                  placeholder="Enter Invoiced Value"
                  required
                />
              </div>

              <div class="form-group col-md-4">
                <label for="">Naira Value</label
                ><input
                  class="form-control"
                  @input="calcPremium()"
                  id="nairavalue"
                  type="text"
                  :value="metaData.nairaValue.toLocaleString()"
                  disabled
                />
              </div>

              <div class="form-group col-md-4">
                <label for="">Sum Insured</label
                ><input
                  class="form-control"
                  @input="calcPremium()"
                  id="suminsured"
                  type="text"
                  :value="data.sumInsured.toLocaleString()"
                  placeholder="Enter Sum Insured"
                  disabled
                />
              </div>

              <div class="form-group col-md-4">
                <label for="">Premium Rate</label
                ><input
                  class="form-control"
                  @input="calcPremium()"
                  id="premiumrate"
                  type="number"
                  v-model="data.premiumRate"
                  placeholder="Enter Premium Rate"
                  step="0.001"
                  :disabled="user.role !== 'Broker' ? true : false"
                />
              </div>

              <div class="form-group col-md-4">
                <label for="">Premium</label
                ><input
                  class="form-control"
                  id="premium"
                  type="text"
                  :value="data.premium.toLocaleString()"
                  placeholder="Enter Premium"
                  disabled
                />
              </div>

              <div class="form-group col-md-4">
                <label for="">Loading (+ %)</label
                ><input
                  class="form-control"
                  id="excess"
                  type="text"
                  :value="metaData.percentLoading.toLocaleString()"
                  disabled
                />
              </div>

              <div class="form-group col-md-4">
                <label for="">Excess</label
                ><input
                  class="form-control"
                  id="excess"
                  type="text"
                  v-model="data.excess"
                  placeholder="Enter Excess"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button v-if="loading" class="btn btn-primary float-right disabled">
              <div
                class="spinner-border spinner-border-sm mx-1 text-light"
                role="status"
              ></div>
              Loading...
            </button>
            <button
              v-else-if="user.role == 'Agent' && !metaData.paid"
              @click.prevent="flwPay()"
              type="button"
              class="btn btn-primary"
            >
              Make Payment
            </button>
            <button v-else type="sumbit" class="btn btn-primary">
              Book Certificate
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
//import AppLayout from "@/layouts/AppLayout.vue";
import { mapState, mapActions } from "pinia";
import Swal from "sweetalert2";
import { useMarineStore } from "@/stores/marine";
import { useAdminStore } from "@/stores/admin";
import { useMainStore } from "@/stores/main";
import $config from "../../helpers/config";

export default {
  name: "Buy Additional Marine Certificate",
  props: ["singleBusiness"],
  //components: { AppLayout },
  data() {
    return {
      metaData: {
        currency: {},
        nairaValue: "",
        percentLoading: "",
        check: "",
        policyEnd: "",
        paid: false,
      },

      tranrsactionreference: "",
      minRate: 0.05,

      data: {
        insuredId: "",
        policyNo: "",
        submittedBy: "Marine Portal",
        tranrsactionreference: "",
        tin: "",
        makePayment: false,
        bvn: "",
        startDate: "",
        coverScope: "MOC",
        duration: 0,
        transtype: "",
        conveyance: "",
        sumInsured: "",
        premium: 0,
        premiumRate: 0,
        typeofCover: "",
        endorsement: " ",
        from: "",
        to: "",
        exRate: "",
        exCurrency: "",
        cargoDescription: "",
        voyageType: "",
        parkingType: "",
        invoicedValue: "",
        excess: "N20,000 or 10% of claims (Whichever Is Higher)",
        certificateType: "Open Cover",
        endorsementOptions: " ",
        proformaInvoiceNo: "",
        //brokersRefNo: "",
        vessel: "",
        lienClause: "",
        natureofCargo: "",
        addClause: [],
        addClauseOpenCover: [],
      },
    };
  },
  computed: {
    ...mapState(useMarineStore, [
      "loading",
      "componentLoading",
      "currencyRates",
      "brokerRate",
    ]),
    ...mapState(useAdminStore, [
      "natureOfCargos",
      "conveyances",
      "voyageTypes",
      "parkingTypes",
    ]),
    ...mapState(useMainStore, ["user"]),
  },

  methods: {
    ...mapActions(useMarineStore, [
      "getCoverScopes",
      "getCurrency",
      "getBrokerRate",
      "makePayment",
      "buyAdditionalMarine",
      "uploadDocument",
      "warning",
    ]),
    ...mapActions(useAdminStore, [
      "getAllConveyances",
      "getAllNatureOfCargos",
      "getAllParkingTypes",
      "getAllVoyageTypes",
    ]),

    async uploadFile() {
      const doc = new FormData();
      doc.append("file", this.metaData.fileData);

      await this.uploadDocument(doc);
    },

    handleFileUpload(e) {
      this.data.imageFile = e.target.files[0].name;
      this.metaData.fileData = e.target.files[0];
    },

    addDays(date, days) {
      if (this.data.startDate !== "" && this.data.duration !== 0) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        this.metaData.policyEnd = `${result.getDate()}/${
          result.getMonth() + 1
        }/${result.getFullYear()}`;
      }
    },

    calcPremium() {
      if (this.data.invoicedValue !== "") {
        this.metaData.nairaValue = this.data.invoicedValue * this.data.exRate;
        this.metaData.percentLoading = 0.1 * this.metaData.nairaValue;
        this.data.sumInsured =
          this.metaData.nairaValue + this.metaData.percentLoading;

        let calculatedPremium =
          (this.data.premiumRate / 100) * parseInt(this.data.sumInsured);

        this.data.premium = calculatedPremium;
      }
    },

    async getRate() {
      if (
        this.data.typeofCover != "" &&
        this.data.coverScope != "" &&
        this.data.voyageType != ""
      ) {
        await this.getBrokerRate(
          this.user.role === "Broker" ? this.user.brokerId : "110002330419",
          this.data.typeofCover,
          this.data.coverScope,
          this.data.voyageType
        ).then(() => {
          if (this.brokerRate.name <= 0) this.minRate = 0.05;
          else {
            this.minRate = this.brokerRate.name;
            if (this.user.role !== "Broker")
              this.data.premiumRate = this.brokerRate.name;
          }
          this.calcPremium();
        });
      }
    },

    setCurrency(currency) {
      this.data.exCurrency = currency.exCurrency;
      this.data.exRate = parseFloat(currency.rates);
    },

    async handleSubmit() {
      if (this.data.premiumRate < this.minRate) {
        this.warning(
          "The Premium Rate Is Below The Approved Rate On This Portal, Please Enter A Valid Rate."
        );
        return;
      }
      if (this.user.role == "Broker") await this.uploadFile();
      this.sendMarineRequest();
    },

    async flwPay() {
      // $Session.setItem("data", JSON.stringify(this.data));
      // $Session.setItem("metadata", JSON.stringify(this.metaData));
      this.data.tranrsactionreference = `CHIMARINETXR-${Math.floor(
        Math.random() * 100000000
      )}`;

      // eslint-disable-next-line no-undef
      const modal = FlutterwaveCheckout({
        public_key: $config.flwLivePublicKey,
        tx_ref: this.data.tranrsactionreference,
        amount: this.data.premium,
        currency: "NGN",
        payment_options: "card, banktransfer, ussd",
        onclose: () => {
          modal.close();
        },

        callback: () => {
          this.metaData.paid = true;
          modal.close();
          // $Session.setItem("data", JSON.stringify(this.data));
          // $Session.setItem("metadata", JSON.stringify(this.metaData));
        },
        meta: {
          agent_id: this.user.brokerId,
        },
        customer: {
          email: this.user.email,
          name: this.user.broker,
        },
        customizations: {
          title: "Consolidated Hallmark Insurance",
          description: "Payment for marine insurance",
          logo: "https://s3-symbol-logo.tradingview.com/consolidated-hallmark-insurance-plc--600.png",
        },
      });
    },

    async sendMarineRequest() {
      const res = await this.buyAdditionalMarine(this.data);
      if (res.isSuccessful) {
        Swal.fire({
          icon: "success",
          title: "Success",
          showCancelButton: this.user.role == "Broker" ? true : false,
          cancelButtonText: "Pay Later",
          confirmButtonText:
            this.user.role == "Broker" ? "Make Payment" : "Done",
          reverseButtons: true,
          text: "Additional Certificate Booked Successfully",
          confirmButtonColor: "#990100",
        }).then((result) => {
          if (result.isConfirmed) {
            if (this.user.role == "Broker") {
              this.tranrsactionreference = `CHIMARINETXR-${Math.floor(
                Math.random() * 100000000
              )}`;

              //eslint-disable-next-line no-undef
              FlutterwaveCheckout({
                public_key: $config.flwLivePublicKey,
                tx_ref: this.tranrsactionreference,
                amount: this.data.premium,
                currency: "NGN",
                payment_options: "card, banktransfer, ussd",
                callback: () => {
                  this.makePayment({
                    certificateNo: [res.certificateNo],
                    startDate: this.data.startDate,
                    policyno: this.data.policyNo,
                    coverScope: "MARINE OPEN COVER",
                    sumInsured: this.data.sumInsured,
                    premium: this.data.premium,
                    premiumRate: this.data.premiumRate,
                    endorsement: this.data.endorsement,
                    submittedBy: "Marine Portal",
                    transactionReference: this.tranrsactionreference,
                  }).then(() => {
                    //window.location.reload();
                  });
                },
                meta: {
                  broker_id: this.user.brokerId,
                },
                customer: {
                  email: this.user.email,
                  name: this.user.broker,
                },
                customizations: {
                  title: "Consolidated Hallmark Insurance",
                  description: "Payment for marine insurance",
                  logo: "https://s3-symbol-logo.tradingview.com/consolidated-hallmark-insurance-plc--600.png",
                },
              });
            } else {
              location.reload();
            }
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            location.reload();
          }
        });
      }
    },

    disableDate() {
      var dtToday = new Date();

      var month = dtToday.getMonth() + 1;
      var day = dtToday.getDate();
      var year = dtToday.getFullYear();
      if (month < 10) month = "0" + month.toString();
      if (day < 10) day = "0" + day.toString();

      let maxDate = year + "-" + month + "-" + day;
      const dob = document.getElementById("nstartdate");
      dob.setAttribute("min", maxDate);
    },
  },

  created() {
    this.data = {
      ...this.data,
      ...{
        bvn: this.singleBusiness.bvn,
        insuredId: this.singleBusiness.insuredId,
        policyNo: this.singleBusiness.policyno,
        tin: this.singleBusiness.tin,
        addClause: this.singleBusiness.addClause,
        addClauseOpenCover: this.singleBusiness.addClauseOpenCover,
        mktStaff: this.user.broker,
        mktStaffID: this.user.brokerId,
      },
    };
    this.getCurrency();
    this.getAllConveyances();
    this.getAllNatureOfCargos();
    this.getAllParkingTypes();
    this.getAllVoyageTypes();
  },

  mounted() {
    setTimeout(this.disableDate(), 1000);
  },
};
</script>
